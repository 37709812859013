* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0 !important;
}

.react-typewriter-text {
  font-weight: bolder;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy.otf");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("../fonts/Gilroy-Medium.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Gilroy";
  background-color: #000;
}

body::-webkit-scrollbar {
  width: 0.6em;
}

body::-webkit-scrollbar-track {
  background: #e5e5e5;
}

body::-webkit-scrollbar-thumb {
  background-color: #006539;
  outline: none;
}

a,
button {
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

a {
  color: #038a50;
}

a:hover {
  text-decoration: none;
}

.margin-top-7 {
  margin-top: 7rem !important;
}
/* style header */
header {
  background: #000;
  padding: 40px 0 28px;
  z-index: 10000;
  position: relative;
  /* box-shadow: 0 4px 20px 1px rgba(255, 255, 255, 0.219); */
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 4px 20px 1px rgb(0 26 15);
  background-color: #000;
  /* animation: 500ms ease-in-out 0s normal none 1 running fadeInDown; */

  padding: 15px 0;
}

header .ul-desktop li {
  padding-right: 36px;
}

header .ul-desktop li:last-child {
  padding-right: 0;
}

header ul li a {
  font-family: "Gilroy-Medium";
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #f5fbf2;
  position: relative;
  z-index: 100;
}

header ul li a::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0px;
  height: 4px;
  background: #00dd9c;
  transition: all 0.45s;
  border-radius: 29px;
  text-transform: capitalize;
}

header ul li a:hover:hover {
  color: #00e3a5;
}

header ul li a:hover::after {
  width: 100%;
}

/* style sidebar */
.nav-menu {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 300px;
  right: -100%;
  background-color: #000;
  box-shadow: -2px 2px 10px 0 rgb(0 26 15);
  overflow-x: hidden;
  transition: 0.5s;
}

.nav-menu a {
  line-height: 65px;
}

.nav-menu.active {
  right: 0;
  transition: 0.5s;
}

.burger {
  color: #f5fbf2;
  font-size: 22px;
  cursor: pointer;
}

.icon-close {
  color: #f5fbf2;
  font-size: 22px;
  position: relative;
  left: 30px;
  top: 15px;
  cursor: pointer;
}

header .social {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

header .social a i {
  color: #80ab9e;
  border: 1px solid;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  padding: 10px 0;
  text-align: center;
}

.container-padding {
  padding-left: 140px;
  padding-right: 140px;
}

.pages {
  background: #000;
  padding: 45px 0 0px;
}

.intro-banner {
  position: relative;
  text-align: center;
  z-index: 1;
  background: #000;
}

.intro-banner .content i {
  filter: drop-shadow(2px 4px 6px #28b46f);
  font-size: 38px;
  color: #28b46f;
}

.banner-video {
  position: relative;
}

.intro-banner .banner-video::before {
  position: absolute;
  content: " ";
  height: 211px;
  left: 0;
  width: 100%;
  top: -1px;
  background: linear-gradient(180deg, #000000 26.12%, rgba(0, 0, 0, 0) 100%);
}
.binary-placeholder {
  display: none;
}
/* .banner-video::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #038a50;
  mix-blend-mode: hue;
  width: 100%;
  height: 100%;
} */
.overlay-content {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #038a50;
  mix-blend-mode: hue;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
}

.banner2 video {
  height: auto !important;
}

/* .banner2::before {
  position: absolute;
  content: " ";
  left: 0;
  top: -1px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    178.85deg,
    #000000 1%,
    rgba(0, 0, 0, 0.58) 51.46%,
    #000000 82.66%
  );
} */
.overlay-content-black {
  position: absolute;
  content: " ";
  left: 0;
  top: -1px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    178.85deg,
    #000000 1%,
    rgba(0, 0, 0, 0) 51.46%,
    #000000 82.66%
  );
}
.intro-banner .content {
  position: absolute;
  width: 100%;
  left: 0;
  top: 7px;
  color: #f5fbf2;
  z-index: 1;
}

.intro-banner .content h1 {
  font-family: "Gilroy";
  font-weight: 300;
  font-size: 100px;
  line-height: 94px;
  letter-spacing: 0.15em;
  text-shadow: 17px 4px 13px #000000;
  margin-bottom: 70px;
  text-align: center !important;
}

.intro-banner .content p {
  font-weight: 400;
  font-size: 30px;
  line-height: 47px;
  text-shadow: 17px 4px 13px #000000;
  margin-bottom: 66px;
}

.link-meet {
  background: #038a50;
  padding: 16.5px 44px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  color: #fff;
  border: 1px solid #038a50;
}

.link-meet:hover,
.about-section a:hover {
  background: #000;
  color: #fff;
}

.intro-banner .bg1 {
  background-image: url("../img/bg1.png");
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: -1.5px;
  width: 950px;
  height: 850px;
  background-repeat: no-repeat;
}

.intro-content {
  background: #000;
  position: relative;
  z-index: 1;
}

.intro-banner::after {
  background: rgb(0 0 0);
  position: absolute;
  content: "";
  right: 0;
  width: 100%;
  height: 300px;
  bottom: 149px;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  transform: matrix(1, 0, 0, 2, 0, 0);
}

.mission-section h2 {
  font-size: 36px;
  line-height: 150%;
  text-transform: uppercase;
  font-family: "Gilroy-Medium";
  color: #f5fbf2;
  margin-bottom: 8px;
  text-align: center;
}

.mission-section p {
  font-size: 20px;
  line-height: 32px;
  color: #fefefe;
  margin-bottom: 4rem;
}

.mission-section li {
  list-style-type: none;
}
.mission-section .box-img {
  border: 0.6px solid #fefefe;
  display: inline-block;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  padding: 20px;
}

.service-section .box-img {
  margin: auto;
  position: relative;
}

.service-section .box-img img {
  width: 100%;
  height: 286.38px;
  object-fit: contain;
  position: relative;
  z-index: 10;
}

/* .service-section .box-img::before,
.service-section .box-img::after {
  content: "";
  background: #fefefe;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 136px;
}

.service-section .box-img::before {
  left: 50px;
}

.service-section .box-img::after {
  right: 50px;
} */

.mission-section .box-img img {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.mission-section .box-shadow {
  padding: 20px;
  position: relative;
}

.overlay {
  position: absolute;
  filter: blur(77px);
  -webkit-filter: blur(77px);
  -moz-filter: blur(77px);
  -o-filter: blur(77px);
  -ms-filter: blur(77px);
  left: 115px;
  top: 50px;
  width: 130px;
  height: 135.98px;
  z-index: 1;
  background: #28b46f;
  content: "";
}

.mission-section .box-shadow img {
  width: 111.26px;
  height: 135.98px;
}

.mission-section h5 {
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.08em;
  color: #fefefe;
  margin-top: 35px;
}

.mission-section .box {
  position: relative;
}

.mission-section .box1 .line,
.line-respo {
  border-top: 0.7px solid #fff;
  position: absolute;
  right: -74px;
  width: 150px;
  top: 70px;
}

.line-respo {
  display: none;
}

.mission-section .box2 .line {
  border-top: 0.7px solid #fff;
  position: absolute;
  left: -74px;
  width: 150px;
  top: 70px;
}

.mission-section .box3 .line {
  background: #fff;
  text-align: center;
  margin: 1rem auto 1.7rem;
  height: 150px;
  width: 0.7px;
  position: relative;
}

.mission-section .box3 .line2,
.service-section .line {
  height: 391px;
  width: 1px;
  margin: 1.7rem auto 1.7rem;
  background-image: linear-gradient(
    180deg,
    #fefefe 41.08%,
    rgba(254, 254, 254, 0) 103.6%
  );
  position: relative;
}

.service-section .line {
  transform: rotate(180deg);
  margin: auto;
  bottom: -5px;
}

.service-section .line2 {
  transform: rotate(0);
  height: 260px;
  background: #fefefe;
  bottom: 0;
}

.mission-section .box3 .line::before,
.mission-section .box3 .line2::before,
.service-section .line3::before,
.service-section .line3::after {
  content: "";
  background: #fefefe;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: -3px;
  top: 0;
}

.service-section .line::before,
.service-section .line3::before,
.service-section .line3::after {
  width: 12px;
  height: 12px;
  left: -6px;
  top: -6px;
}

.service-section .line3::after {
  right: -6px;
  left: auto;
}

.mission-section .box3 .line::after {
  content: "";
  background: #fefefe;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: -3px;
  bottom: 0;
}

.mission-section .box1 .line::before,
.mission-section .box2 .line::before,
.line-respo::before {
  content: "";
  background: #fefefe;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  left: 0;
  top: -4px;
}

.mission-section .box2 .line::after,
.mission-section .box1 .line::after,
.line-respo::after {
  content: "";
  background: #fefefe;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  position: absolute;
  right: 0;
  top: -4px;
}

.Polygon3Image {
  top: -43rem;
  right: 0;
  z-index: 1;
}

.weThinkTextBox {
  top: 30%;
  z-index: 1000;
  margin: 0 auto;
}

.WeTImg {
  margin-right: 0.5rem;
}

.weThinkParagraph {
  width: 493.07px;
  font-size: 18px;
  line-height: 32px;
  color: #fefefe;
  background-color: #000;
  padding: 5px;
}

.weThinkParagraphSmallSize {
  display: none;
  font-size: 18px;
  line-height: 32px;
  color: #fefefe;
  /* background-color: #000; */
  padding: 5px 1rem;
}

.weThinkParagraph .react-typewriter-text-wrap {
  display: inline-block;
}

.weThinkParagraph .react-typewriter-text {
  font-size: 18px;
  font-weight: 400;
}

.service-section .line3 {
  margin: auto;
  width: 179.5px;
  border-top: 1px solid #fefefe;
  position: relative;
}

.service-section h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  padding: 20px 62px 22px;
  margin-bottom: 0;
}

.service-section .line4 {
  width: 282px;
}

.service-section .line5 {
  height: 120px;
  transform: rotate(360deg);
  z-index: 1;
  left: -5px;
}

.service-section .line5::before {
  display: none;
}

.service-section .title-serv {
  font-family: "Gilroy-Bold";
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 12px;
}

.service-section .desc {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #80ab9e;
  margin-bottom: 93px;
}

.service-section .col-serv {
  margin-top: 12rem;
}

.service-section {
  position: relative;
}

.service-section::before {
  background-image: url("../img/Polygon3.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  left: 0;
  top: -89px;
  width: 750px;
  height: 1250px;
  z-index: 1;
  transform: rotate(180deg);
}

.banner3 {
  position: relative;
  text-align: center;
  top: -80px;
  background-image: url("../img/banner3.png");
  height: 800px;
  background-size: cover;
}

.banner3 img {
  width: 669px;
  height: 705.74px;
  object-fit: cover;
}

.banner3::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 800px;
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(0, 0, 0, 0) 20.51%,
    #000000 100%
  );
}

.about-section .co-bg {
  position: relative;
  right: 0;
  top: 0;
}

.about-section h1 {
  font-size: 100px;
  color: #fff;
  text-transform: uppercase;
  float: right;
}

.about-section h1 span {
  color: #28b46f;
  font-family: "Gilroy-Medium";
  font-weight: 700;
}

.about-section .co-bg::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 540px;
  height: 345px;
  background: #00ff91;
  mix-blend-mode: color;
}

.about-section .co-bg img {
  width: 659px;
  height: 334px;
  object-fit: contain;
  position: absolute;
}

.about-section ul li {
  color: #fefefe;
  font-size: 26px;
  line-height: 36px;
  text-transform: capitalize;
  margin-bottom: 16px;
  position: relative;
  list-style: none;
  padding-left: 27px;
}

.about-section ul li:last-child {
  margin-bottom: 31px;
}

.about-section ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 15px;
  width: 6px;
  height: 6px;
  background: #038a50;
  border-radius: 50%;
}

.about-section a {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  background: #038a50;
  padding: 16px 44px;
  color: #ffffff;
  border: 1px solid #038a50;
}

.moreFaqSectionMargin {
  padding: 50px 0 50px;
}

.faq-section {
  padding: 100px 0 100px;
}

.faq-section h2 {
  font-size: 132px;
  line-height: 94px;
  text-align: center;
  color: #f5fbf2;
  margin-bottom: 77px;
}

.faq-section .MuiPaper-root {
  background: none;
}

.faq-section h5 {
  font-size: 20px;
  line-height: 29px;
  font-family: "Gilroy-Bold";
  color: #fefefe;
  margin-bottom: 0;
}

.faq-section p,
.faq-section ul {
  font-size: 18px;
  line-height: 29px;
  color: #80ab9e;
  margin-bottom: 0;
}

.faq-section ul {
  margin-left: 20px;
}

.faq-section .MuiAccordionSummary-content {
  margin: 0 !important;
}

.faq-section .MuiAccordionDetails-root {
  padding: 0 20px 50px 10px;
}

.faq-section .MuiPaper-root::before {
  border-bottom: 2px solid #80ab9e;
}

.faq-section .Mui-expanded:before {
  opacity: 1 !important;
}

.faq-section .MuiButtonBase-root {
  padding: 20px 16px 20px 0;
}

.faq-section .MuiAccordionSummary-expandIconWrapper {
  color: #fefefe;
}

.faq-section .link {
  margin-top: 91px;
}

.faq-section .link a {
  font-size: 18px;
  line-height: 29px;
  font-family: "Gilroy-Bold";
  color: #fefefe;
}

.faq-section .link a i {
  font-size: 15px;
  padding-left: 13px;
  position: relative;
  top: 1px;
}

.faq-section .link a:hover {
  color: #038a50;
}

.box-hover .img-hover {
  opacity: 0;
  position: absolute;
  top: -30rem;
  transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -webkit-transition: all 0.5s ease-in;
}

.box-hover:hover .img-hover {
  opacity: 1;
  top: 0;
}

.box-hover:hover .WeTImg {
  opacity: 0;
  transition: all 0.7s ease-in;
  -moz-transition: all 0.7s ease-in;
  -webkit-transition: all 0.7s ease-in;
}

.banner2 h4 {
  font-size: 23px;
  line-height: 27px;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  margin: 26px 0 39px;
  font-family: "Gilroy-Medium";
}

/* stye footer */
footer {
  background: #000;
  background-image: url("../img/bg-footer.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  height: 520px;
}

footer .img-logo {
  width: 306px;
  height: 96px;
  margin-bottom: 48px;
}

footer h6 {
  font-size: 24px;
  line-height: 35px;
  color: #f5fbf2;
  font-family: "Gilroy-Bold";
}

footer ul li a,
footer p {
  font-size: 20px;
  color: #80ab9e;
  line-height: 44px;
  position: relative;
}

footer ul li a::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0px;
  height: 4px;
  background: #00dd9c;
  -webkit-transition: all 0.45s;
  transition: all 0.45s;
  border-radius: 29px;
}

footer ul li a:hover {
  color: #f5fbf2;
}

footer ul li a:hover::after {
  width: 100%;
}

footer .social {
  margin: 28px 0 30px;
}

footer .social a {
  padding-right: 30px;
}

footer .social a:last-child {
  padding-right: 0;
}

footer .social a i {
  color: #80ab9e;
  border: 1px solid;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  padding: 13px 0;
  text-align: center;
  transform: rotate(0);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

footer .social a:hover i {
  transform: rotate(360deg);
  color: #038a50;
}

.bg-mobile {
  display: none;
}

/* style about page */
.about-page {
  background-color: #000;
  /* padding-bottom: 210px; */
}

.about-page .box-head {
  margin-bottom: 100px;
}

.box-head h2,
.service-page h2 {
  color: #f5fbf2;
  letter-spacing: 0.02em;
  /* text-transform: uppercase; */
  line-height: 94.1px;
  font-size: 48px;
  margin-bottom: 0;
}

.box-head h2 span {
  font-size: 32px;
}

.box-head p,
.service-page p {
  font-size: 20px;
  line-height: 32px;
  color: #fefefe;
}

.box-list {
  height: 230px;
  background: linear-gradient(90deg, #000000 34.08%, rgba(0, 0, 0, 0) 100%);
  padding: 60px 0;
  color: #fefefe;
  margin-bottom: 29px;
  position: relative;
  margin: 0 7rem 1.5rem 7rem;
}

.box-list .border-top {
  position: absolute;
  content: "";
  top: 0;
  width: 100%;
  left: 0;
  border-top: 0.6px solid #fefefe;
}

.box-list .border-bottom {
  position: absolute;
  content: "";
  bottom: 0;
  border-bottom: 0.6px solid #fefefe;
  width: 100%;
  left: 0;
}

.chevron::before,
.chevron::after {
  border-color: #fefefe;
  border-style: solid;
  border-width: 0.6px 0.6px 0 0;
  content: "";
  display: inline-block;
  position: absolute;
  vertical-align: top;
  width: 161px;
  height: 163px;
  top: 33px;
}

.chevron::before {
  left: -81px;
  transform: rotate(-135deg);
}

.chevron::after {
  top: 33px;
  right: -80px;
  transform: rotate(45deg);
}

.box-list img {
  width: 61px;
  height: 61px;
  margin-bottom: 11px;
}

.box-list p {
  letter-spacing: 0.08em;
  font-size: 14px;
  line-height: 21px;
}

.box-list h5 {
  font-size: 20px;
  line-height: 32px;
}

.about-page .list {
  position: relative;
}

.about-page .list .img-right {
  position: absolute;
  right: 0;
  bottom: -3em;
  width: 300px;
  opacity: 0.5;
}

.about-page .list .img-left {
  position: absolute;
  left: 0;
  top: -50px;
  transform: rotate(180deg);
  width: 440px;
}

/* style service page */
.service-page .box-img {
  position: relative;
}

.service-page .box-img img {
  height: 500px;
  object-fit: cover;
}

.service-page .box-img::after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  top: 0;
  /* background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0.61) 0%,
    #000000 100%
  ); */
  mix-blend-mode: darken;
  height: 500px;
}

.service-page .row-grid {
  padding-bottom: 120px;
}

.service-page .row-grid:nth-child(2) {
  flex-direction: row-reverse;
}

.service-page .row-grid:nth-child(2) .box-img img {
  height: 370px;
  object-fit: contain;
}

.service-page .row-grid:nth-child(2) .box-img::after,
.service-page .row-grid:nth-child(3) .box-img::after {
  display: none;
}

.service-page .row-grid:nth-child(3) .box-img img {
  object-fit: contain;
}

.contact-box {
  border: 0.6px solid #fefefe;
  padding: 60px 80px;
}
.ourStoryContactBox {
  border: 0.6px solid #fefefe;
  padding: 20px 40px;
  margin-bottom: 5em;
}

.ourStoryContactBox h2 {
  font-size: 42px;
  line-height: 94px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #f5fbf2;
  margin-bottom: 0;
}

.contact-box h3 {
  font-size: 36px;
  line-height: 24px;
  font-family: "Gilroy-Medium";
  color: #fefefe;
  margin-bottom: 16px;
}

.contact-box p {
  font-size: 18px;
  line-height: 24px;
  color: rgba(254, 254, 254, 0.8);
}

.row-bottom h2 {
  font-size: 42px;
  line-height: 94px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #f5fbf2;
  margin-bottom: 0;
}

.row-bottom h2 span {
  font-size: 32px;
}

.row-bottom p {
  font-size: 20px;
  line-height: 32px;
  color: #fefefe;
  padding-right: 8rem;
}

.about-page .line {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border: 0.5px solid #ffffff;
  width: 90px;
}

.contact-box a,
.about-page a {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  background: #038a50;
  padding: 16px 44px;
  color: #ffffff;
  border: 1px solid #038a50;
}
.faqAnswerAchor {
  border: none !important;
  background: none !important;
  padding: 0 !important;
  color: #038a50 !important;
}
.contact-box a:hover,
.about-page a:hover {
  background: #000;
  color: #fff;
}

.contact-page {
  background: #000 !important;
}

.contact-page .btn-send:hover {
  background: #00cd74;
}

/* style contact page */
.contact-page .box-head h2 {
  margin-bottom: 39px;
}

.contact-page fieldset {
  border-color: #fff;
  border-radius: 2px;
}

.MuiInputBase-root:hover fieldset,
.MuiInputBase-root.Mui-focused fieldset {
  border-color: #fff !important;
}

.contact-page .MuiFormControl-root {
  margin-bottom: 24px;
}

.contact-page .MuiFormLabel-root {
  color: #fff;
  font-family: "Gilroy";
}

.contact-page .MuiFormLabel-root.Mui-focused {
  color: #fff;
}

.contact-page input,
.contact-page textarea {
  color: #fff;
  font-family: "Gilroy";
  border-color: #fff !important;
}

.contact-page textarea {
  height: 222px !important;
}

.contact-page .btn-send {
  /* font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  border: 1px solid #00cd74;
  padding: 16px 50px;
  color: #ffffff;
  background: none;
  font-family: "Gilroy-Medium"; */
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  background: #038a50;
  padding: 16px 44px;
  color: #ffffff;
  border: 1px solid #038a50;
}

.contact-page .btn-send:hover {
  background: #000;
  color: #fff;
}

.contact-page img {
  position: absolute;

  right: 0;
  width: 940px;
  height: 781px;
  object-fit: contain;
}

.logo-text-color {
  color: #038a50 !important;
}
.img-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5em;
}
/* responsive */

@media (min-width: 2450px) and (max-width: 2700px) {
  .service-section h2 {
    padding: 20px 180px 22px;
  }
}

@media (min-width: 1900px) and (max-width: 2450px) {
  .service-section h2 {
    padding: 20px 150px 22px;
  }
}

@media (min-width: 2160px) {
  .service-section h2 {
    padding: 20px 200px 22px;
  }
}

@media (min-width: 2460px) {
  .service-section h2 {
    padding: 20px 250px 22px;
  }
}

@media (min-width: 1900px) {
  .intro-banner .bg1 {
    width: 1096.92px;
    height: 1070.92px;
  }

  .intro-banner::after {
    height: 400px;
    bottom: 199px;
  }

  .banner3 img {
    margin-top: 5em;
  }
}

@media (min-width: 1600px) and (max-width: 1900px) {
  .intro-banner .bg1 {
    width: 1000px;
    height: 950px;
  }
}

@media (min-width: 1500px) and (max-width: 1900px) {
  .service-section h2 {
    padding: 20px 100px 22px;
  }

  .banner3 img {
    margin-top: 2em;
  }
}

@media (max-width: 1400px) {
  .contact-page img {
    width: 790px;
  }

  .service-section::before {
    top: 0;
    width: 640px;
    height: 1050px;
  }

  .service-section h2 {
    padding: 20px 42px 22px;
  }
}

@media (max-width: 1330px) {
  .banner-video video {
    height: 850px;
    object-fit: cover;
  }
}

@media (max-width: 1300px) {
  .container-padding {
    padding-left: 90px;
    padding-right: 90px;
  }
}

@media (max-width: 1199.5px) {
  header .ul-desktop li {
    padding-right: 28px;
  }

  .contact-page img {
    width: 660px;
  }

  .contact-box a {
    padding: 16px 50px;
  }

  .box-list h5 {
    font-size: 18px;
    line-height: 25px;
  }

  .container-padding {
    padding-left: 60px;
    padding-right: 60px;
  }

  .mission-section .box1 .line {
    right: -103px;
  }

  .mission-section .box2 .line {
    left: -103px;
  }

  .overlay {
    left: 81px;
  }

  .service-section::before {
    top: 90px;
    width: 420px;
    height: 865px;
  }

  .service-section h2 {
    padding: 20px 16px 22px;
  }

  .service-section .desc {
    margin-bottom: 55px;
  }

  .weThinkTextBox {
    top: 120px;
  }

  .Polygon3Image {
    width: 500px;
    top: -38rem;
  }

  .about-section h1 {
    font-size: 90px;
  }
}

@media (max-width: 991.5px) {
  .service-page p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .box-head:last-child {
    flex-direction: column-reverse;
  }

  .about-page .list .img-right {
    display: none;
  }

  .contact-page img {
    display: none;
  }

  .service-page p {
    padding-bottom: 2rem;
  }

  .box-head h2,
  .service-page h2 {
    font-size: 35px;
  }

  .box-head h2 span {
    font-size: 25px;
  }

  .intro-banner::after {
    height: 250px;
    bottom: 124px;
  }

  .intro-banner .bg1 {
    width: 630px;
    height: 630px;
  }

  .banner3 {
    top: auto;
  }

  .mission-section .box-img {
    padding: 10px;
  }

  .mission-section .box1 .line,
  .mission-section .box2 .line {
    width: 100px;
    top: 61px;
  }

  .mission-section .box2 .line {
    left: -75px;
  }

  .mission-section .box1 .line {
    right: -75px;
  }

  .mission-section .box-shadow img {
    width: 90px;
    height: 120px;
    object-fit: contain;
  }

  .overlay {
    left: 50px;
  }

  .mission-section .box3 .line {
    margin: 1rem auto 1.5rem;
  }

  .mission-section .box3 .line2,
  .service-section .line {
    height: 180px;
  }

  .mission-section .box3 .line2::before {
    top: -3px;
  }

  .Polygon3Image {
    width: 360px;
    top: -25rem;
  }

  .weThinkTextBox {
    top: 70px;
  }

  .box-hover .img-hover,
  .WeTImg {
    width: 45px;
  }

  .box-hover img:last-child {
    width: 190px;
  }

  .banner2 h4 {
    margin: 25px 0;
  }

  .service-section-flex {
    flex-direction: column-reverse;
  }

  .service-section::before {
    top: 40em;
    width: 19em;
    height: 780px;
  }

  .service-section .col-serv {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .service-section .design {
    margin-left: -4em;
  }

  .service-section .Development {
    margin-left: 1em;
  }

  .service-section .QA {
    margin-left: -7.5em;
  }

  .service-section h2 {
    padding: 20px 195px 22px;
  }

  .about-section h1 {
    margin-bottom: 2.5rem;
    float: left;
  }
}

@media (max-width: 767.5px) {
  .row-bottom p {
    padding: 0;
  }

  .intro-banner::after {
    height: 200px;
    bottom: 99px;
  }

  .intro-banner .bg1 {
    width: 575px;
    height: 575px;
  }

  .col-respo {
    margin-bottom: 60px;
  }

  .shape1 {
    order: -1;
    position: relative;
  }

  .contact-box {
    padding: 60px 20px;
  }

  .contact-box h3,
  .box-head h2,
  .service-page h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px !important;
  }

  .contact-box h3 {
    margin-bottom: 10px !important;
  }

  .box-head p,
  .service-page p,
  .row-bottom p {
    font-size: 18px;
    line-height: 30px;
  }

  .box-list {
    margin: 0 0 2rem 0;
    padding: 20px 0;
    text-align: center;
    height: auto;
  }

  .box-list:last-child {
    margin-bottom: 0;
  }

  .box-list .border-top,
  .chevron,
  .box-list .border-bottom {
    display: none;
  }

  .intro-banner .content h1 {
    margin-bottom: 40px;
  }

  .banner3,
  .banner3::after {
    height: 650px;
  }

  .overlay {
    left: 190px;
    width: 120px;
    height: 120px;
  }

  .about-section .co-bg img {
    width: 100%;
    height: auto;
  }

  .about-section .co-bg::after {
    width: 100%;
    height: 430px;
  }

  .banner3 img {
    width: 500px;
    height: 500px;
  }

  .service-section::before {
    top: 43.5em;
    width: 14em;
    height: 570px;
  }

  .service-section .box-img img {
    height: 190.38px;
  }

  .service-section h2 {
    padding: 20px 170px 22px;
  }

  .mission-section .box1 .line,
  .mission-section .box2 .line2 {
    height: 180px;
    width: 1px;
    background-image: linear-gradient(
      180deg,
      #fefefe 41.08%,
      rgba(254, 254, 254, 0) 103.6%
    );
    position: relative;
    top: 0;
    margin: 1rem auto 0;
    transform: none;
    background-color: transparent;
    border: none;
  }

  .mission-section .box1 .line {
    transform: rotate(-15deg);
    left: 20px;
  }

  .mission-section .box2 .line2 {
    transform: rotate(15deg);
    right: 20px;
  }

  .mission-section .box2 .line2::before,
  .mission-section .box1 .line::before {
    content: "";
    background: #fefefe;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    position: absolute;
    left: -3px;
    top: -2px;
  }

  .mission-section h5 {
    margin: 15px 0 0 0;
    font-size: 12px;
  }

  .mission-section .box3 .line2 {
    margin: 1rem auto 0;
  }

  .col-respo {
    margin-bottom: 0;
  }

  .overlay {
    left: auto;
    width: 100%;
    height: 120px;
    top: 20px;
  }

  .mission-section .box-shadow {
    padding: 0;
  }

  .shape1 {
    order: 1;
  }

  .mission-section .box-img img {
    width: 50px;
    height: 50px;
  }

  .mission-section {
    margin-top: 5rem;
  }

  .mission-section .box-shadow img {
    width: 80px;
  }

  .line-respo1 {
    display: block !important;
    height: 200px;
    width: 1px;
    margin: auto;
    background-image: linear-gradient(
      180deg,
      #fefefe 41.08%,
      rgba(254, 254, 254, 0) 103.6%
    );
    position: relative;
    top: 0;
    left: 0;
  }

  .line-respo::before {
    left: -3px;
    right: -3px;
  }

  .row-respo .small:first-child,
  .row-respo .small:last-child {
    order: -1;
  }

  .row-respo .small:nth-child(3) h5 {
    padding: 0 1.5rem;
  }

  .mission-section .box2 .line,
  .mission-section .box3 .line,
  .line-respo,
  .line-respo3,
  .line-respo4,
  .line-respo::after,
  .mission-section .box1 .line::after {
    display: none;
  }
}

@media (max-width: 650px) {
  .service-section h2 {
    padding: 20px 109px 22px;
  }

  .Polygon3Image {
    width: 270px;
    top: -18rem;
  }

  .box-hover .img-hover,
  .WeTImg {
    width: 30px;
  }

  .box-hover img:last-child {
    width: 130px;
  }

  .weThinkParagraph {
    width: 100%;
    padding: 0 0.5rem;
  }

  .banner2 h4 {
    margin: 15px 0;
  }
}
@media (max-width: 575.5px) and (orientation: landscape) {
  .binary-placeholder {
    display: inline-block;
    width: 100%;
  }

  .overlay-content,
  .overlay-content-black {
    display: none;
  }
  .binary-video {
    display: none;
  }

  .global-placeholder {
    height: 70vh;
    object-fit: cover;
  }
}
@media (max-width: 575.5px) {
  .binary-placeholder {
    display: inline-block;
    width: 100%;
  }

  .overlay-content,
  .overlay-content-black {
    display: none;
  }
  .binary-video {
    display: none;
  }

  .global-placeholder {
    height: 70vh;
    object-fit: cover;
  }

  .row-bottom h2 {
    font-size: 32px;
  }
  .row-bottom h2 span {
    font-size: 25px;
  }
  .service-section .design {
    margin-left: -6.5em;
  }

  .service-section .Development {
    margin-left: -4em;
  }

  .service-section .QA {
    margin-left: -8.5em;
  }

  .service-section .title-serv h1 {
    font-size: 20px;
  }

  .intro-banner .bg1 {
    width: 321px;
    height: 321px;
  }

  .intro-banner::after {
    height: 120px;
    bottom: 59px;
  }

  .banner-video video {
    height: 500px;
  }

  .service-section::before {
    top: 46.5em;
    width: 10em;
    height: 21em;
  }

  .contact-box a {
    padding: 16px 10px;
    display: block;
    text-align: center;
    width: 100%;
  }

  .about-page .list .img-left,
  .about-page .list .img-right {
    width: 250px;
  }

  .about-page .list .img-left {
    top: 50%;
  }

  .box-head h2 span {
    font-size: 25px;
  }

  .box-head h2 {
    font-size: 32px;
  }

  .about-page .list .img-right {
    bottom: 50%;
  }

  .bg-mobile img {
    width: 100%;
    position: absolute;
    bottom: -188px;
    left: 0;
  }

  .intro-banner .banner-video::before {
    height: 120px;
  }

  .intro-banner .content p {
    font-size: 20px;
    line-height: 36px;
  }

  /* .service-section::before {
      display: none;
  } */

  .service-section h2 {
    padding: 20px 50px 22px;
    font-size: 15px;
    line-height: 25px;
  }

  .about-section ul li {
    font-size: 20px;
  }

  .about-section .co-bg img,
  .about-section .co-bg::after {
    top: -27px;
  }

  .about-section .co-bg img {
    width: 85%;
    left: 1.5em;
  }

  .faq-section {
    padding: 50px 0 50px;
  }

  .about-section h1,
  .faq-section h2 {
    font-size: 60px;
    font-weight: 700;
  }

  .about-section .ulList {
    padding-left: 1.5rem !important;
    padding-right: 0.5rem !important;
  }

  .intro-banner .content h1 {
    font-size: 60px;
  }

  .banner3 img {
    width: 100%;
    object-fit: contain;
  }

  .banner3,
  .banner3::after {
    height: 580px;
  }

  .container-padding {
    padding-left: 15px;
    padding-right: 15px;
  }

  footer {
    height: 100%;
    background-size: cover;
    background-position: bottom;
    background-color: #000;
  }

  footer .img-logo {
    width: 230px;
    object-fit: contain;
  }

  .Polygon3Image {
    width: 185px;
    top: -12rem;
  }

  .weThinkParagraph {
    padding: 1rem 0.5rem;
    display: none;
  }

  .weThinkParagraphSmallSize {
    display: block;
  }

  .weThinkTextBox {
    top: 1em;
  }

  .service-section {
    margin-top: 5rem;
  }

  /* .mission-section .box1 .line {
    height: 29vw;
    top: -4vw;
    left: 106px;
  } */
}

@media (max-width: 450px) {
  .service-section .box-img img {
    height: 140px;
  }

  .service-section::before {
    top: 45em;
    width: 8em;
    height: 21em;
  }
}

@media (max-width: 400px) {
  .service-section {
    margin-top: 9rem;
  }

  footer .list-footer {
    display: grid !important;
  }
}

@media (max-width: 325px) {
  .mission-section .box-img {
    padding: 5px;
  }
}

@media (max-width: 350px) {
  .service-section::before {
    top: 47em;
    width: 6em;
    height: 17em;
  }

  .about-section h1,
  .faq-section h2,
  .intro-banner .content h1 {
    font-size: 40px;
  }

  .about-section .co-bg img {
    width: 80%;
    left: 3em;
  }

  .about-section .co-bg::after {
    left: 2em;
  }
}

@media (max-width: 310px) {
  .about-section h1,
  .faq-section h2 {
    font-size: 50px;
  }

  .service-section .line4 {
    width: 225px;
  }

  .service-section h2 {
    padding: 20px 15px 22px;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
